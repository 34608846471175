import React from "react";
import buttonBg from "../images/buttonBg.gif";

const ButtonPrimary = ({ text }) => {
  return (
    <button
      className='w-36 p-1 rounded text-white hover:text-accentColor'
      style={{
        backgroundImage: `url(${buttonBg})`,
        backgroundSize: "",
        backgroundPosition: "left -22px",
      }}
    >
      {text}
    </button>
  );
};

export default ButtonPrimary;
