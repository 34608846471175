import React from 'react'

const PersonalDataField = ({name, value}) => {
  return (
      <div className='flex text-xs'>
          <h3 style={{width: "210px"}}>{name}</h3>
          <h3 className='text-left'>{value}</h3>
      </div>
  )
}

export default PersonalDataField