import React from "react";
import logoImage from "../images/ase_logo.gif";
import { NavLink, Outlet } from "react-router-dom";
import buttonBg from "../images/buttonBg.gif";

const Antet = () => {
  return (
    <>
      <div className='flex between justify-between'>
        <div>
          <img className='h-40' src={logoImage} alt='imagine ase' />
        </div>
        <div className='text-2xl text-right font-bold text-accentColor'>
          <h1 style={{ borderBottom: "1px solid #3a3a3a" }}>SIMUR</h1>
          <h1 style={{ borderBottom: "1px solid #3a3a3a" }}>
            Academia de Studii Economice Bucuresti
          </h1>
          <h1 style={{ borderBottom: "1px solid #3a3a3a" }}>
            Pagina personala a studentului: DANCU ANDREI-SEBASTIAN
          </h1>
        </div>
      </div>
      {/* Navigation  */}
      <div
        className='flex text-white text-sm mb-10 rounded'
        style={{
          backgroundImage: `url(${buttonBg})`,
          backgroundPosition: "left -25px",
        }}
      >
        <NavLink
          className='hover:text-accentColor px-2'
          style={({ isActive }) => {
            return {
              fontWeight: isActive ? "bold" : "",
              backgroundColor: isActive ? "#494949" : "",
            };
          }}
          to='/'
        >
          Home
        </NavLink>
        <NavLink
          className='hover:text-accentColor px-2'
          style={({ isActive }) => {
            return {
              fontWeight: isActive ? "bold" : "",
              backgroundColor: isActive ? "#494949" : "",
            };
          }}
          to='/note'
        >
          Note
        </NavLink>
      </div>
      <Outlet />
    </>
  );
};

export default Antet;
