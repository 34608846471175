import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Antet from "./components/Antet";
import Home from "./pages/Home";
import Note from "./pages/Note";
import Login from "./pages/Login";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);

  return (
    <>
      {loggedIn ? (
        <div
          className='App p-4 min-h-screen'
          style={{ backgroundColor: "#292929" }}
        >
          <BrowserRouter>
            <Routes>
              {/* Antet is always rendered */}
              <Route path='/' element={<Antet />}>
                {/* Home is rendered on the index route and /note route */}
                <Route index element={<Home />} />
                <Route path='note' element={<Note />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </div>
      ) : (
        <div>
          <Login setLogin={setLoggedIn} />
        </div>
      )}
    </>
  );
}

export default App;
