import React from "react";
import MaterieOrar from "../components/MaterieOrar";

const Note = () => {
  return (
    <div style={{ color: "white" }} className='text-xs'>
      {/* First box  */}
      <table className='w-full mb-8 rounded'>
        <thead>
          <tr
            style={{ backgroundColor: "#1a1a1a" }}
            className='flex p-2 text-left'
          >
            <th style={{ width: "200px" }}>An studiu</th>
            <th style={{ width: "200px" }}>Semestru</th>
            <th>An universitar</th>
          </tr>
        </thead>
        <tbody>
          <tr
            style={{ backgroundColor: "#494949" }}
            className='flex p-2 text-left'
          >
            <th style={{ width: "200px" }}>1</th>
            <th style={{ width: "200px" }}>1</th>
            <th>2023 - 2024</th>
          </tr>
        </tbody>
      </table>
      {/* Second box */}
      <table className='w-full rounded'>
        <thead>
          <tr
            style={{ backgroundColor: "#1a1a1a" }}
            className='flex gap-3 p-2 text-left'
          >
            <th style={{ width: "188px" }}>Facultate</th>
            <th style={{ width: "126px" }}>Denumire Disciplina</th>
            <th style={{ width: "80px" }}>Tip Examen</th>
            <th style={{ width: "100px" }}>Forma Evaluare</th>
            <th style={{ width: "109px" }}>Nota Validata</th>
            <th style={{ width: "118px" }}>Nr credite obtinute</th>
            <th style={{ width: "67px" }}>Frauda</th>
            <th style={{ width: "89px" }}>Echiv Partial</th>
            <th>Data Nota</th>
          </tr>
        </thead>
        <tbody>
          <tr
            style={{ backgroundColor: "#494949" }}
            className='flex p-2  flex-col'
          >
            <MaterieOrar
              faculty='MARKETING'
              name='Bazele programării calculatoarelor'
              examType='Standard'
              evaluation='Verificare'
              mark='9'
              credits='3.00'
              fraud='NU'
              partial='0'
              date='16/10/2024'
            />
            <MaterieOrar
              faculty='MARKETING'
              name='Educație fizică și sport'
              examType='Standard'
              evaluation='Verificare'
              mark='10'
              credits='1.00'
              fraud='NU'
              partial='0'
              date='4/10/2024'
            />
            <MaterieOrar
              faculty='MARKETING'
              name='Marketing'
              examType='Standard'
              evaluation='Verificare'
              mark='10'
              credits='2.00'
              fraud='NU'
              partial='0'
              date='20/10/2024'
            />
            <MaterieOrar
              faculty='MARKETING'
              name='Limba Engleză'
              examType='Standard'
              evaluation='Verificare'
              mark='10'
              credits='2.00'
              fraud='NU'
              partial='0'
              date='20/10/2024'
            />
            <MaterieOrar
              faculty='MARKETING'
              name='Statistica'
              examType='Standard'
              evaluation='Verificare'
              mark='9'
              credits='2.00'
              fraud='NU'
              partial='0'
              date='29/10/2024'
            />
            <MaterieOrar
              faculty='MARKETING'
              name='Macroeconomie'
              examType='Standard'
              evaluation='Verificare'
              mark='8'
              credits='5.00'
              fraud='NU'
              partial='0'
              date='9/11/2024'
            />
            <MaterieOrar
              faculty='MARKETING'
              name='Design si Estetic'
              examType='Standard'
              evaluation='Verificare'
              mark='10'
              credits='2.00'
              fraud='NU'
              partial='0'
              date='11/12/2024'
            />
            <MaterieOrar
              faculty='MARKETING'
              name='Management'
              examType='Standard'
              evaluation='Verificare'
              mark='10'
              credits='5.00'
              fraud='NU'
              partial='0'
              date='13/12/2024'
            />
            <MaterieOrar
              faculty='MARKETING'
              name='Integrare Economică Europeană'
              examType='Standard'
              evaluation='Verificare'
              mark='9'
              credits='3.00'
              fraud='NU'
              partial='0'
              date='15/12/2024'
            />
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Note;
