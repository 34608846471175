import React from "react";

const MaterieOrar = ({
  faculty,
  name,
  examType,
  evaluation,
  mark,
  credits,
  fraud,
  partial,
  date,
}) => {
  return (
    <tr className='text-left mb-4'>
      <th style={{ width: "200px", fontWeight: "600", textAlign: "left" }}>
        {faculty}
      </th>
      <th style={{ fontWeight: "400", width: "140px"}}>
        {name}
      </th>
      <th style={{ fontWeight: "400", width: "90px"}}>
        {examType}
      </th>
      <th style={{ fontWeight: "400", width: "115px"}}>
        {evaluation}
      </th>
      <th style={{ fontWeight: "400", width: "120px"}}>
        {mark}
      </th>
      <th style={{ fontWeight: "400", width: "130px" }}>
        {credits}
      </th>
      <th style={{ fontWeight: "400", width: "80px"}}>
        {fraud}
      </th>
      <th style={{ fontWeight: "400", width: "100px"}}>
        {partial}
      </th>
      <th style={{ fontWeight: "400" }}>{date}</th>
    </tr>
  );
};

export default MaterieOrar;
