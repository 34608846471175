import { useState } from "react";
import aseLogin from "../images/ase_login.png";

const Login = ({ setLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = () => {
    if (username !== "andidancu89@yahoo.com" && password !== "asdqwe123") {
      alert("Nume sau parola gresite!");
    } else {
      setLogin(true);
    }
  };

  return (
    <div>
      <div
        style={{ backgroundColor: "#222639", color: "#9D9D9D" }}
        className='h-12 flex justify-between px-10 py-2 text-lg'
      >
        <p>ACADEMIA DE STUDII ECONOMICE BUCURESTI</p>
        <p>Pagina personală a studentului</p>
      </div>
      {/* Login block  */}
      <div
        className='mt-12 mx-auto shadow-md border p-3 '
        style={{ width: "400px", backgroundColor: "#FAFAFA" }}
      >
        <img className='mb-8' src={aseLogin} alt='logo ase' />
        <input
          type='text'
          name='username'
          value={username}
          onChange={handleUsernameChange}
          placeholder='Utilizator'
          className='py-3 px-4 w-full border border-gray-300 rounded-lg shadow mb-2'
        />
        <input
          type='password'
          name='password'
          value={password}
          onChange={handlePasswordChange}
          placeholder='Parola'
          className='py-3 px-4 w-full border border-gray-300 rounded-lg shadow mb-2'
        />
        <button
          style={{ backgroundColor: "#337ab7" }}
          className='text-white text-center w-full py-3 px-4 rounded-lg'
          onClick={handleLogin}
        >
          Conectare
        </button>
      </div>
    </div>
  );
};

export default Login;
