import React from "react";
import PersonalDataField from "../components/PersonalDataField";
import proficePicture from "../images/profilePic.jpg";
import ButtonPrimary from "../components/ButtonPrimary";

const Home = () => {
  return (
    <div style={{ color: "#d7d7d7" }}>
      {/* First box  */}
      <div style={{ backgroundColor: "#494949" }} className='p-1 mb-2'>
        <div
          style={{ borderBottom: "1px solid #3a3a3a" }}
          className='text-accentColor'
        >
          Date personale
        </div>
        <div className='flex flex-col gap-1'>
          <PersonalDataField
            name='Nume prenume'
            value='DANCU ANDREI-SEBASTIAN'
          />
          <PersonalDataField name='Cod bare/Cod student' value='S22001266' />
          <PersonalDataField name='Data nastere' value='WEDNESDAY 30.10.2002' />
          <PersonalDataField
            name='Localitate nastere'
            value='SAT CORNI JUD BOTOSANI'
          />
          <PersonalDataField name='Adresa' value='Sat Corni Judetul Botosani' />
          <PersonalDataField name='Localitate' value='Corni' />
          <PersonalDataField
            name='Localitate'
            value={
              <img
                className='h-64'
                src={proficePicture}
                alt='poza profil'
              ></img>
            }
          />
          <PersonalDataField
            name='Numar telefon ORANGE'
            value={
              <div className='flex gap-2'>
                <input
                  className='border border-black rounded w-64 px-1'
                  style={{ backgroundColor: "#444444" }}
                  type='text'
                  value='0725286280'
                ></input>
                <ButtonPrimary text='Modifica numar' />
              </div>
            }
          />
          <PersonalDataField
            name='Adresa e-mailHelp'
            value={
              <div className='flex gap-2'>
                <input
                  className='border border-black rounded w-64 px-1'
                  style={{ backgroundColor: "#444444" }}
                  type='text'
                  value='andidancu89@yahoo.com'
                ></input>
                <ButtonPrimary text='Modifica adresa e-mail' />
              </div>
            }
          />
        </div>
      </div>

      {/* Second box  */}
      <h3 className='text-accentColor font-bold text-sm'>Date şcolarizare</h3>
      <div style={{ backgroundColor: "#494949" }} className='p-3 text-sm'>
        <h6 className='font-bold'>1. FACULTATEA DE MARKETING</h6>
        <p
          style={{ borderBottom: "1px solid #3a3a3a" }}
          className='text-accentColor mb-2'
        >
          Specializarea: MARKETING
        </p>
        <div className='flex gap-64'>
          <div>
            <PersonalDataField name='An universitar' value='2023-2024' />
            <PersonalDataField name='Semestru' value='1' />
            <PersonalDataField name='Stare' value='Activ' />
            <PersonalDataField
              name='Forma de invatamant'
              value='FRECVENTĂ REDUSĂ'
            />
          </div>
          <div>
            <PersonalDataField name='An studiu' value='2' />
            <PersonalDataField name='Grupa' value='1706' />
            <PersonalDataField name='Seria' value='FR' />
            <PersonalDataField name='Forma finantare' value='TAXĂ' />
          </div>
        </div>
      </div>
      <h1 className='text-red-700 text-xl text-center mt-4'>
        Plata integrala trebuie efectuata pana pe data de 31.08.2024, folosind
        acelasi card ca pentru inregistrarea anilor trecuti*
      </h1>
    </div>
  );
};

export default Home;
